<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList" />
  </div>
</template>

<script>
import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CompanyListView",
  components: {
    ViewTemplateWithTable,
  },
  data() {
    return {
      panel: {
        module: "Suporte",
        title: "Empresa",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/support/company/get-all",
        urlDeleteAllApi: "/api/v1/adm/user/delete",
        showChecks: false,
        headerTable: [
          {
            field: "id",
            title: "Id",
            type: "text",
          },
          {
            field: "companyName",
            title: "Razão Social/Nome",
            type: "link",
            iconSearch: true,
            routeName: "supportCompanyUpdate",
          },
          {
            field: "tradeName",
            title: "Fantasia/Apelido",
            type: "text",
            iconSearch: true,
          },
          {
            field: "statusName",
            title: "Status",
            type: "class",
            fieldComparison: "statusName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "badge badge-primary text-center",
                fieldComparison: "Ativa",
              },
              { classCss: "badge badge-danger text-center", fieldComparison: "Inativa" },
            ],
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    create() {
      this.$router.push({
        name: "userCreate",
      });
      this.removeLoading(["btnUserCreate"]);
    },
  },
};
</script>
